import foto1 from './../../photo/Portfolio/a1-1.webp'
import foto2 from './../../photo/Portfolio/a1-2.webp'
import foto3 from './../../photo/Portfolio/1-3.webp'
import foto4 from './../../photo/Portfolio/a1-4.webp'
import foto5 from './../../photo/Portfolio/a1-6.webp'
import foto6 from './../../photo/Portfolio/a1-7.webp'
import foto7 from './../../photo/Portfolio/2-1.webp'
import foto8 from './../../photo/Portfolio/2-2.webp'
import foto9 from './../../photo/Portfolio/2-3.webp'
import foto10 from './../../photo/Portfolio/2-4.webp'
import foto11 from './../../photo/Portfolio/2-5.webp'
import foto12 from './../../photo/Portfolio/2-6.webp'
import foto13 from './../../photo/Portfolio/2-7.webp'
import foto14 from './../../photo/Portfolio/2-8.webp'
import foto15 from './../../photo/Portfolio/2-9.webp'
import foto16 from './../../photo/Portfolio/2-10.webp'
import foto17 from './../../photo/Portfolio/2-11.webp'
import foto18 from './../../photo/Portfolio/2-12.webp'
import foto19 from './../../photo/Portfolio/2-13.webp'
import foto21 from './../../photo/Portfolio/3-1.webp'
import foto22 from './../../photo/Portfolio/3-2.webp'
import foto23 from './../../photo/Portfolio/3-3.webp'
import foto24 from './../../photo/Portfolio/3-4.webp'
import foto26 from './../../photo/Portfolio/4-2.webp'
import foto27 from './../../photo/Portfolio/4-3.webp'
import foto29 from './../../photo/Portfolio/4-5.webp'
import foto31 from './../../photo/Portfolio/4-7.webp'
import foto32 from './../../photo/Portfolio/4-8.webp'
import foto33 from './../../photo/Portfolio/IMG_6912.webp'
import foto34 from './../../photo/Portfolio/IMG_6917.webp'
import foto35 from './../../photo/Portfolio/IMG_7173.webp'
import foto36 from './../../photo/Portfolio/IMG_7209.webp'
import foto37 from './../../photo/Portfolio/IMG_7256.webp'
import foto38 from './../../photo/Portfolio/IMG_7270.webp'
import foto39 from './../../photo/Portfolio/IMG_7335.webp'
import foto40 from './../../photo/Portfolio/IMG_7677.webp'
import foto41 from './../../photo/Portfolio/IMG_8367.webp'
import foto42 from './../../photo/Portfolio/IMG_8394.webp'
import foto43 from './../../photo/Portfolio/IMG_8450.webp'
import foto62 from './../../photo/Portfolio/IMG_1901.webp'
import foto63 from './../../photo/Portfolio/IMG_1914.webp'
import foto64 from './../../photo/Portfolio/IMG_1916.webp'
import foto65 from './../../photo/Portfolio/IMG_1962.webp'
import foto66 from './../../photo/Portfolio/IMG_1967.webp'
import foto67 from './../../photo/Portfolio/IMG_2107.webp'
import foto68 from './../../photo/Portfolio/IMG_2110.webp'
import foto69 from './../../photo/Portfolio/IMG_7907.webp'
import foto44 from './../../photo/Portfolio/IMG_8459.webp'
import foto45 from './../../photo/Portfolio/IMG_8602.webp'
import foto46 from './../../photo/Portfolio/IMG_8709.webp'
import foto47 from './../../photo/Portfolio/IMG_8724.webp'
import foto48 from './../../photo/Portfolio/IMG_8818.webp'
import foto49 from './../../photo/Portfolio/IMG_8840.webp'
import foto50 from './../../photo/Portfolio/IMG_9150.webp'
import foto51 from './../../photo/Portfolio/IMG_9249.webp'
import foto52 from './../../photo/Portfolio/IMG_9391.webp'
import foto53 from './../../photo/Portfolio/IMG_9393.webp'
import foto54 from './../../photo/Portfolio/IMG_5-9523.webp'
import foto55 from './../../photo/Portfolio/IMG_5-9532.webp'
import foto56 from './../../photo/Portfolio/IMG_5-9542.webp'
import foto57 from './../../photo/Portfolio/IMG_5-9558.webp'
import foto58 from './../../photo/Portfolio/IMG_5-9576.webp'
import foto59 from './../../photo/Portfolio/IMG_5-9583.webp'
import foto60 from './../../photo/Portfolio/IMG_5-9593.webp'
import foto61 from './../../photo/Portfolio/IMG_5-9596.webp'
import foto70 from './../../photo/Portfolio/0502202401.webp'
import foto71 from './../../photo/Portfolio/0502202402.webp'
import foto72 from './../../photo/Portfolio/0502202403.webp'
import foto101 from './../../photo/new/1IMG_3973.webp'
import foto102 from './../../photo/new/1IMG_3975.webp'
import foto103 from './../../photo/new/1IMG_3978.webp'
import foto104 from './../../photo/new/1IMG_5221.webp'
import foto105 from './../../photo/new/1IMG_5266.webp'
import foto106 from './../../photo/new/1IMG_5269.webp'
import foto107 from './../../photo/new/1IMG_5273.webp'
import foto108 from './../../photo/new/1IMG_5274.webp'
import foto109 from './../../photo/new/1IMG_5275.webp'
import foto111 from './../../photo/new/1IMG_5279.webp'
import foto112 from './../../photo/new/1IMG_5293.webp'
import foto113 from './../../photo/new/1IMG_5299.webp'
import foto114 from './../../photo/new/1IMG_5305.webp'
import foto115 from './../../photo/new/1IMG_5306.webp'
import foto116 from './../../photo/new/1IMG_5308.webp'
import foto117 from './../../photo/new/1IMG_5309.webp'
import foto118 from './../../photo/new/1IMG_5310.webp'
import foto119 from './../../photo/new/1IMG_5311.webp'
import foto121 from './../../photo/new/1IMG_5313.webp'
import foto122 from './../../photo/new/1IMG_5314.webp'
import foto123 from './../../photo/new/1IMG_5315.webp'
import foto124 from './../../photo/new/1IMG_5319.webp'
import foto125 from './../../photo/new/1IMG_5321.webp'
import foto126 from './../../photo/new/1IMG_5322.webp'
import foto127 from './../../photo/new/1IMG_5324.webp'
import foto128 from './../../photo/new/1IMG_5327.webp'
import foto129 from './../../photo/new/1IMG_5328.webp'
import foto130 from './../../photo/new/1IMG_5332.webp'
import foto131 from './../../photo/new/1IMG_5333.webp'
import foto132 from './../../photo/new/1IMG_5335.webp'
import foto133 from './../../photo/new/1IMG_5350.webp'
import foto134 from './../../photo/new/1IMG_5353.webp'
import foto135 from './../../photo/new/1IMG_5356.webp'
import foto136 from './../../photo/new/1IMG_5362.webp'
import foto137 from './../../photo/new/1IMG_6816.webp'
import foto138 from './../../photo/new/1IMG_6818.webp'
import foto139 from './../../photo/new/1IMG_6820.webp'
import foto140 from './../../photo/new/1IMG_6822.webp'
import foto141 from './../../photo/new/1IMG_6824.webp'
import foto142 from './../../photo/new/1IMG_6825.webp'
import foto143 from './../../photo/new/1IMG_6826.webp'
import foto144 from './../../photo/new/1IMG_6827.webp'
import foto145 from './../../photo/new/1IMG_6831.webp'
import foto146 from './../../photo/new/1IMG_6834.webp'
import foto147 from './../../photo/new/1IMG_6836.webp'
import foto148 from './../../photo/new/2IMG_4594.webp'
import foto149 from './../../photo/new/2IMG_4602.webp'
import foto150 from './../../photo/new/2IMG_4606.webp'
import foto151 from './../../photo/new/2IMG_4611.webp'
import foto152 from './../../photo/new/2IMG_4634.webp'
import foto153 from './../../photo/new/2IMG_4678.webp'
import foto154 from './../../photo/new/2IMG_4685.webp'
import foto155 from './../../photo/new/2IMG_4686.webp'
import foto156 from './../../photo/new/2IMG_4696.webp'
import foto157 from './../../photo/new/2IMG_4697.webp'
import foto158 from './../../photo/new/2IMG_4705.webp'
import foto159 from './../../photo/new/2IMG_4712.webp'
import foto160 from './../../photo/new/2IMG_4720.webp'
import foto161 from './../../photo/new/2IMG_4726.webp'
import foto162 from './../../photo/new/2IMG_4732.webp'
import foto163 from './../../photo/new/2IMG_4742.webp'
import foto164 from './../../photo/new/2IMG_4900.webp'
import foto165 from './../../photo/new/2IMG_4901.webp'
import foto166 from './../../photo/new/2IMG_4905.webp'
import foto167 from './../../photo/new/2IMG_4910.webp'
import foto168 from './../../photo/new/2IMG_4920.webp'
import foto169 from './../../photo/new/2IMG_4922.webp'
import foto170 from './../../photo/new/2IMG_4933.webp'
import foto171 from './../../photo/new/2IMG_4937.webp'
import foto172 from './../../photo/new/2IMG_4939.webp'
import foto173 from './../../photo/new/2IMG_4965.webp'
import foto174 from './../../photo/new/2IMG_4970.webp'
import foto175 from './../../photo/new/2IMG_4977.webp'
import foto176 from './../../photo/new/2IMG_4991.webp'
import foto177 from './../../photo/new/2IMG_5009.webp'
import foto178 from './../../photo/new/2IMG_5014.webp'
import foto179 from './../../photo/new/2IMG_6885.webp'
import foto180 from './../../photo/new/2IMG_6888.webp'
import foto181 from './../../photo/new/2IMG_6890.webp'
import foto182 from './../../photo/new/2IMG_6896.webp'
import foto183 from './../../photo/new/2IMG_6909.webp'
import foto184 from './../../photo/new/2IMG_6912.webp'
import foto185 from './../../photo/new/2IMG_6913.webp'
import foto186 from './../../photo/new/2IMG_6915.webp'
import foto187 from './../../photo/new/2IMG_6916.webp'
import foto188 from './../../photo/new/2IMG_6918.webp'
import foto189 from './../../photo/new/2IMG_6920.webp'
import foto190 from './../../photo/new/2IMG_6921.webp'
import foto191 from './../../photo/new/2IMG_6924.webp'
import foto192 from './../../photo/new/2IMG_6926.webp'
import foto193 from './../../photo/new/2IMG_6928.webp'
import foto194 from './../../photo/new/2IMG_6931.webp'
import foto195 from './../../photo/new/2IMG_6932.webp'
import foto196 from './../../photo/new/3IMG_4142.webp'
import foto197 from './../../photo/new/3IMG_4161.webp'
import foto198 from './../../photo/new/3IMG_4164.webp'
import foto199 from './../../photo/new/3IMG_4170.webp'
import foto200 from './../../photo/new/3IMG_4175.webp'
import foto201 from './../../photo/new/3IMG_4184.webp'
import foto202 from './../../photo/new/3IMG_4186.webp'
import foto203 from './../../photo/new/3IMG_4206.webp'
import foto204 from './../../photo/new/3IMG_5452.webp'
import foto205 from './../../photo/new/3IMG_5453.webp'
import foto206 from './../../photo/new/3IMG_5454.webp'
import foto207 from './../../photo/new/3IMG_5456.webp'
import foto208 from './../../photo/new/3IMG_5460.webp'
import foto209 from './../../photo/new/3IMG_5462.webp'
import foto210 from './../../photo/new/3IMG_6037.webp'
import foto211 from './../../photo/new/3IMG_6039.webp'
import foto212 from './../../photo/new/3IMG_6040.webp'
import foto213 from './../../photo/new/3IMG_6041.webp'
import foto214 from './../../photo/new/3IMG_6042.webp'
import foto215 from './../../photo/new/3IMG_6043.webp'
import foto216 from './../../photo/new/3IMG_6048.webp'
import foto217 from './../../photo/new/3IMG_6050.webp'
import foto218 from './../../photo/new/3IMG_6052.webp'

export const portfoliopageData = [
{icon: foto101,alt: 'portfolio image1'},
{icon: foto102,alt: 'portfolio image1'},
{icon: foto103,alt: 'portfolio image1'},
{icon: foto104,alt: 'portfolio image1'},
{icon: foto105,alt: 'portfolio image1'},
{icon: foto106,alt: 'portfolio image1'},
{icon: foto107,alt: 'portfolio image1'},
{icon: foto108,alt: 'portfolio image1'},
{icon: foto109,alt: 'portfolio image1'},
{icon: foto111,alt: 'portfolio image1'},
{icon: foto112,alt: 'portfolio image1'},
{icon: foto113,alt: 'portfolio image1'},
{icon: foto114,alt: 'portfolio image1'},
{icon: foto115,alt: 'portfolio image1'},
{icon: foto116,alt: 'portfolio image1'},
{icon: foto117,alt: 'portfolio image1'},
{icon: foto118,alt: 'portfolio image1'},
{icon: foto119,alt: 'portfolio image1'},
{icon: foto121,alt: 'portfolio image1'},
{icon: foto122,alt: 'portfolio image1'},
{icon: foto123,alt: 'portfolio image1'},
{icon: foto124,alt: 'portfolio image1'},
{icon: foto125,alt: 'portfolio image1'},
{icon: foto126,alt: 'portfolio image1'},
{icon: foto127,alt: 'portfolio image1'},
{icon: foto128,alt: 'portfolio image1'},
{icon: foto129,alt: 'portfolio image1'},
{icon: foto130,alt: 'portfolio image1'},
{icon: foto131,alt: 'portfolio image1'},
{icon: foto132,alt: 'portfolio image1'},
{icon: foto133,alt: 'portfolio image1'},
{icon: foto134,alt: 'portfolio image1'},
{icon: foto135,alt: 'portfolio image1'},
{icon: foto136,alt: 'portfolio image1'},
{icon: foto137,alt: 'portfolio image1'},
{icon: foto138,alt: 'portfolio image1'},
{icon: foto139,alt: 'portfolio image1'},
{icon: foto140,alt: 'portfolio image1'},
{icon: foto141,alt: 'portfolio image1'},
{icon: foto142,alt: 'portfolio image1'},
{icon: foto143,alt: 'portfolio image1'},
{icon: foto144,alt: 'portfolio image1'},
{icon: foto145,alt: 'portfolio image1'},
{icon: foto146,alt: 'portfolio image1'},
{icon: foto147,alt: 'portfolio image1'},
{icon: foto148,alt: 'portfolio image1'},
{icon: foto149,alt: 'portfolio image1'},
{icon: foto150,alt: 'portfolio image1'},
{icon: foto151,alt: 'portfolio image1'},
{icon: foto152,alt: 'portfolio image1'},
{icon: foto153,alt: 'portfolio image1'},
{icon: foto154,alt: 'portfolio image1'},
{icon: foto155,alt: 'portfolio image1'},
{icon: foto156,alt: 'portfolio image1'},
{icon: foto157,alt: 'portfolio image1'},
{icon: foto158,alt: 'portfolio image1'},
{icon: foto159,alt: 'portfolio image1'},
{icon: foto160,alt: 'portfolio image1'},
{icon: foto161,alt: 'portfolio image1'},
{icon: foto162,alt: 'portfolio image1'},
{icon: foto163,alt: 'portfolio image1'},
{icon: foto164,alt: 'portfolio image1'},
{icon: foto165,alt: 'portfolio image1'},
{icon: foto166,alt: 'portfolio image1'},
{icon: foto167,alt: 'portfolio image1'},
{icon: foto168,alt: 'portfolio image1'},
{icon: foto169,alt: 'portfolio image1'},
{icon: foto170,alt: 'portfolio image1'},
{icon: foto171,alt: 'portfolio image1'},
{icon: foto172,alt: 'portfolio image1'},
{icon: foto173,alt: 'portfolio image1'},
{icon: foto174,alt: 'portfolio image1'},
{icon: foto175,alt: 'portfolio image1'},
{icon: foto176,alt: 'portfolio image1'},
{icon: foto177,alt: 'portfolio image1'},
{icon: foto178,alt: 'portfolio image1'},
{icon: foto179,alt: 'portfolio image1'},
{icon: foto180,alt: 'portfolio image1'},
{icon: foto181,alt: 'portfolio image1'},
{icon: foto182,alt: 'portfolio image1'},
{icon: foto183,alt: 'portfolio image1'},
{icon: foto184,alt: 'portfolio image1'},
{icon: foto185,alt: 'portfolio image1'},
{icon: foto186,alt: 'portfolio image1'},
{icon: foto187,alt: 'portfolio image1'},
{icon: foto188,alt: 'portfolio image1'},
{icon: foto189,alt: 'portfolio image1'},
{icon: foto190,alt: 'portfolio image1'},
{icon: foto191,alt: 'portfolio image1'},
{icon: foto192,alt: 'portfolio image1'},
{icon: foto193,alt: 'portfolio image1'},
{icon: foto194,alt: 'portfolio image1'},
{icon: foto195,alt: 'portfolio image1'},
{icon: foto196,alt: 'portfolio image1'},
{icon: foto197,alt: 'portfolio image1'},
{icon: foto198,alt: 'portfolio image1'},
{icon: foto199,alt: 'portfolio image1'},
{icon: foto200,alt: 'portfolio image1'},
{icon: foto201,alt: 'portfolio image1'},
{icon: foto202,alt: 'portfolio image1'},
{icon: foto203,alt: 'portfolio image1'},
{icon: foto204,alt: 'portfolio image1'},
{icon: foto205,alt: 'portfolio image1'},
{icon: foto206,alt: 'portfolio image1'},
{icon: foto207,alt: 'portfolio image1'},
{icon: foto208,alt: 'portfolio image1'},
{icon: foto209,alt: 'portfolio image1'},
{icon: foto210,alt: 'portfolio image1'},
{icon: foto211,alt: 'portfolio image1'},
{icon: foto212,alt: 'portfolio image1'},
{icon: foto213,alt: 'portfolio image1'},
{icon: foto214,alt: 'portfolio image1'},
{icon: foto215,alt: 'portfolio image1'},
{icon: foto216,alt: 'portfolio image1'},
{icon: foto217,alt: 'portfolio image1'},
{icon: foto218,alt: 'portfolio image1'},
    {icon: foto1,alt: 'portfolio image1'},
    {
        icon: foto2,
        alt: 'portfolio image1'
    },
    {
        icon: foto3,
        alt: 'portfolio image1'
    },
    {
        icon: foto4,
        alt: 'portfolio image1'
    },
    {
        icon: foto5,
        alt: 'portfolio image1'
    },
    {
        icon: foto6,
        alt: 'portfolio image1'
    },
    {
        icon: foto7,
        alt: 'portfolio image1'
    },
    {
        icon: foto8,
        alt: 'portfolio image1'
    },
    {
        icon: foto9,
        alt: 'portfolio image1'
    },
    {
        icon: foto10,
        alt: 'portfolio image1'
    },
    {
        icon: foto11,
        alt: 'portfolio image1'
    },
    {
        icon: foto12,
        alt: 'portfolio image1'
    },
    {
        icon: foto37,
        alt: 'portfolio image1'
    },
    {
        icon: foto38,
        alt: 'portfolio image1'
    },
    {
        icon: foto13,
        alt: 'portfolio image1'
    },
    {
        icon: foto14,
        alt: 'portfolio image1'
    },
    {
        icon: foto15,
        alt: 'portfolio image1'
    },
    {
        icon: foto16,
        alt: 'portfolio image1'
    },
    {
        icon: foto17,
        alt: 'portfolio image1'
    },
    {
        icon: foto18,
        alt: 'portfolio image1'
    },
    {
        icon: foto19,
        alt: 'portfolio image1'
    },
    {
        icon: foto21,
        alt: 'portfolio image1'
    },
    {
        icon: foto22,
        alt: 'portfolio image1'
    },
    {
        icon: foto23,
        alt: 'portfolio image1'
    },
    {
        icon: foto24,
        alt: 'portfolio image1'
    },
    {
        icon: foto26,
        alt: 'portfolio image1'
    },
    {
        icon: foto27,
        alt: 'portfolio image1'
    },
    {
        icon: foto29,
        alt: 'portfolio image1'
    },
    {
        icon: foto31,
        alt: 'portfolio image1'
    },
    {
        icon: foto32,
        alt: 'portfolio image1'
    },
    {
        icon: foto33,
        alt: 'portfolio image1'
    },
    {
        icon: foto34,
        alt: 'portfolio image1'
    },
    {
        icon: foto35,
        alt: 'portfolio image1'
    },
    {
        icon: foto36,
        alt: 'portfolio image1'
    },
    {
        icon: foto39,
        alt: 'portfolio image1'
    },
    {
        icon: foto40,
        alt: 'portfolio image1'
    },
    {
        icon: foto41,
        alt: 'portfolio image1'
    },
    {
        icon: foto42,
        alt: 'portfolio image1'
    },
    {
        icon: foto43,
        alt: 'portfolio image1'
    },
    {
        icon: foto67,
        alt: 'portfolio image1'
    },
    {
        icon: foto68,
        alt: 'portfolio image1'
    },
    {
        icon: foto69,
        alt: 'portfolio image1'
    },
    {
        icon: foto44,
        alt: 'portfolio image1'
    },
    {
        icon: foto62,
        alt: 'portfolio image1'
    },
    {
        icon: foto63,
        alt: 'portfolio image1'
    },
    {
        icon: foto64,
        alt: 'portfolio image1'
    },
    {
        icon: foto65,
        alt: 'portfolio image1'
    },
    {
        icon: foto66,
        alt: 'portfolio image1'
    },
    {
        icon: foto45,
        alt: 'portfolio image1'
    },
    {
        icon: foto46,
        alt: 'portfolio image1'
    },
    {
        icon: foto47,
        alt: 'portfolio image1'
    },
    {
        icon: foto48,
        alt: 'portfolio image1'
    },
    {
        icon: foto49,
        alt: 'portfolio image1'
    },
    {
        icon: foto50,
        alt: 'portfolio image1'
    },
    {
        icon: foto51,
        alt: 'portfolio image1'
    },
    {
        icon: foto52,
        alt: 'portfolio image1'
    },
    {
        icon: foto53,
        alt: 'portfolio image1'
    },
    {
        icon: foto54,
        alt: 'portfolio image1'
    },
    {
        icon: foto55,
        alt: 'portfolio image1'
    },
    {
        icon: foto56,
        alt: 'portfolio image1'
    },
    {
        icon: foto57,
        alt: 'portfolio image1'
    },
    {
        icon: foto58,
        alt: 'portfolio image1'
    },
    {
        icon: foto59,
        alt: 'portfolio image1'
    },
    {
        icon: foto60,
        alt: 'portfolio image1'
    },
    {
        icon: foto61,
        alt: 'portfolio image1'
    },
    {
        icon: foto70,
        alt: 'portfolio image1'
    },
    {
        icon: foto71,
        alt: 'portfolio image1'
    },
    {
        icon: foto72,
        alt: 'portfolio image1'
    }

]