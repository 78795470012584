import foto1 from './../../../photo/new/1IMG_6836.webp'
import foto2 from './../../../photo/new/1IMG_6818.webp'
import foto3 from './../../../photo/new/2IMG_6924.webp'
import foto4 from './../../../photo/new/2IMG_6920.webp'
import foto5 from './../../../photo/new/2IMG_6915.webp'
import foto6 from './../../../photo/Portfolio/2-2.webp'
import foto7 from './../../../photo/Portfolio/IMG_5-9576.webp'
import foto8 from './../../../photo/Portfolio/0502202403.webp'





export const portfolioData = [
    {
        icon: foto1,
        alt: 'never gonna give you up'
    },
    {
        icon: foto2,
        alt: 'never gonna give you up'
    },
    {
        icon: foto3,
        alt: 'never gonna give you up'
    },
    {
        icon: foto4,
        alt: 'never gonna give you up'
    },
    {
        icon: foto5,
        alt: 'never gonna give you up'
    },
    {
        icon: foto6,
        alt: 'never gonna give you up'
    },
    {
        icon: foto7,
        alt: 'never gonna give you up'
    },
    {
        icon: foto8,
        alt: 'never gonna give you up'
    }
]