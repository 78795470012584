import React, { useState } from 'react';
import './style.css'
import { portfoliopageData } from './portfoliopagedata';
import arrowright from './../../photo/arrowright.svg'
import arrowleft from './../../photo/arrowleft.svg'
import exiter from './../../photo/xmark.svg'
import placeholder2 from './../../photo/download.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet-async';

function Portfoliopage() {
    const [currentimg, setCurrentimg] = useState(0);
    const [showmodal, setShowmodal] = useState(false);

    if(showmodal === true){
        document.body.style.overflow = "hidden";
    } else if(showmodal === false){
        document.body.style.overflow = "visible";
    }

    const nextimage = () => {
        if(currentimg === portfoliopageData.length-1){
            setCurrentimg(0)
        } else {
        setCurrentimg(currentimg + 1)
        }
    }

    const previmage = () => {
        if(currentimg === 0){
            setCurrentimg(portfoliopageData.length-1)
        }else{
            setCurrentimg(currentimg - 1)
        }
    }

    function setiamge(index){
        setShowmodal(true)
        setCurrentimg(index)
    }

    function removeimg(){
        setShowmodal(false)
        setCurrentimg(0)
    }

    const handlePointerEvent2 = (e) => {
        let isTouchEvent = e.type === "touchstart" ? true : false;

        let card = e.target;
        let offset = 0;
        let initialX = isTouchEvent ? e.touches[0].clientX - 25 : e.clientX - 25;
    
    
        document.ontouchmove = onPointerMove;
        document.ontouchend = onPointerEnd;
    
        function onPointerMove(e) {
          offset = (isTouchEvent ? e.touches[0].clientX : e.clientX) - initialX;
          if (offset <= -50) {
            nextimage();
            return;
          }
          if (offset >= 50) {
            previmage();
            return;
          }
          card.style.left = offset + "px";
        }
    
        function onPointerEnd(e) {

          document.ontouchmove = null;
          document.ontouchend = null;
        }
      };
    
    function Portfoliopagemodal(number){
        return(
        <div className='portfoliopageImagecontainer'>
            <div className='portfoliopageImageblock'>
                <div className='imagezoomTopbox'>
                    <div className='imagezoomTopboxpagination'>
                        {currentimg+1} / {portfoliopageData.length}
                    </div>
                    <div className='imagezoomTopboxbtn'>
                        <img onClick={removeimg} src={exiter} alt='exiter'/>
                    </div>
                </div>
                <div className='imagezoomArrowsandImage'>
                    <div onClick={previmage} className='imagezoomArrow portleftArrow'><img src={arrowleft} alt='arrowleft'/></div>
                    <div className='imagezoomImagebox' onTouchStart={handlePointerEvent2}>
                        <div className='imagezoomImage'>
                            <img src={portfoliopageData[number].icon} alt={portfoliopageData[number].alt}></img>
                        </div>
                    </div>
                    <div onClick={nextimage} className='imagezoomArrow portrightArrow'><img src={arrowright} alt='arrowright'/></div>
                </div>
            </div>
        </div>
        )
    }

    let index = 'ru'

    if((window.location.pathname).includes("/ee/")){
        index = 'ee'
    }


  return (
    <>
        <Helmet>
            <title>{index === 'ru' ? 'Портфолио' : 'Portfolio'}</title>
            <link rel='alternate' hreflang='et' href='https://greenera.ee/ee/portfolio' />
            <link rel='alternate' hreflang='ru' href='https://greenera.ee/portfolio' />
            <link rel="canonical" href={`https://greenera.ee` + window.location.pathname}/>
        </Helmet>
        <figure className='portfoliopageContainer'>
            <div className='portfoliopageTitle'>{index === 'ru' ? 'Портфолио' : 'Portfolio'}</div>
            <div className='portfoliopageBlock'>
                <div className='portfoliopageGallry'>
                    {
                    portfoliopageData.map((item, index) => {
                        return(
                        // <img onClick={()=>{setiamge(index)}} key={index+ 4500} src={item.icon} alt={item.alt}/>
                        <LazyLoadImage 
                            onClick={()=>{setiamge(index)}} 
                            key={index+ 4500} 
                            src={item.icon} 
                            alt='portfolio image' 
                            effect="blur"
                            height='350px'
                            width='auto'
                            placeholderSrc={placeholder2}
                        />
                        )
                    })}
                </div>
            </div>
        </figure>
        {showmodal ? Portfoliopagemodal(currentimg): ''}
    </>
  );
}

export default Portfoliopage;
